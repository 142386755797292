import React, { useState } from 'react'
import Layout from '../components/layout'
import { Hero } from '../components/CenterWithoutBackgroundHero'
import { graphql } from 'gatsby'
import ProductListing from '../components/ProductListing'
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro'
import * as constants from '../consts/index'
import CategoryComponent from '../components/CategoryComponent'
import SearchFilterComponent from '../components/SearchFilterComponent'
import MobileCategoryComponent from '../components/MobileCategoryComponent'
import SEO from '../components/seo'
// eslint-disable-next-line no-unused-vars
// import * as JsSearch from 'js-search'
import Fuse from 'fuse.js'

// eslint-disable-next-line react/prop-types
const Search = ({ pageContext, data }) => {
  pageContext.nin = ['Category', null]
  const [searchText2, setSearchText2] = useState('')

  const index = new Fuse(data.allTemplateSheetsData.nodes, {
    ignoreLocation: true,
    threshold: 0.3,
    keys: ['psproductname', 'pscategory']
  })

  const items = index.search(searchText2)
  const filteredItems = items.map((item) => item.item)

  return (
    <Layout
      bgColor={constants.bgColor}
      isDark={constants.isDark}
      Hero={
        <Hero
          bgColor={'bg-green-500'}
          heading={
            <>
              Our <span className={'text-gray-100'}>Products</span>
              {/*Our <HighlightedText>Products</HighlightedText>*/}
            </>
          }
          isDark={constants.isDark}
        />
      }
    >
      <SEO
        keywords={[
          `products`,
          `product`,
          `black pepper`,
          `spices supplier`,
          `sinaran rempah`,
          `spices`,
          `spices malaysia`,
        ]}
        title="Category"
      />
      <div tw={'-mt-10'}>
        <MobileCategoryComponent categoryList={data.allSitePage} />
        <div
          tw={'max-w-screen-2xl mx-8 2xl:mx-auto py-8 lg:py-12 flex flex-row'}
        >
          <div className="flex flex-col hidden md:block md:w-1/6">
            <SearchFilterComponent epass={setSearchText2} />
            <CategoryComponent categoryList={data.allSitePage} />
          </div>
          <ProductListing
            pageContext={pageContext}
            data={filteredItems}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Search

export const query = graphql`
  query {
    allSitePage(sort: { fields: internalComponentName }) {
      edges {
        node {
          path
        }
      }
    }
    allTemplateSheetsData(
      sort: { fields: psproductname, order: ASC }
      filter: { pscategory: { nin: ["Category", null] } }
    ) {
      nodes {
        id
        psproductname
        pscategory
        psitemimage1
        psitemcoverimage
        psproductdescription
        pspageslug
        psbrandname
      }
    }
  }
`
